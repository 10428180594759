// src/pages/Subject1.js
import React from 'react';

const Home = () => {
    return (
        <div><section class="hero">
        <h1>Welcome to my Take Home Test</h1>
        <p>Here are my answers to the proposed questions.</p>
        <p>Framework: React JavaScript</p>
        <a href="/subject1" class="cta-button">Start</a>
    </section>
    
    <section class="hero">
    </section></div>

    );
};

export default Home;
